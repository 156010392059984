import { all, call, spawn } from 'redux-saga/effects';
import privacySaga from './privacySaga';
import termsOfUseSaga from './termsOfUseSaga';
import faqSaga from './faqSaga';
import contactUsSaga from './contactUsSaga';
import careersSaga from './careersSaga';
import aboutUsSaga from './aboutUsSaga';
import servicesSaga from './servicesSaga';
import projectsSaga from './projectsSaga';
import BlogSaga from './blog';
import themePageSaga from './themePageSaga';
import homeSaga from './homeSaga';
import siteInfoSaga from './siteInfoSaga';
import cancellationSaga from './cancellationSaga';
import refundSaga from './refundSaga';
import cookieSaga from './cookieSaga';


/**
 * add all the saga in the sagas array
 */
const sagas = [
  privacySaga,
  termsOfUseSaga,
  faqSaga,
  contactUsSaga,
  careersSaga,
  aboutUsSaga,
  servicesSaga,
  projectsSaga,
  BlogSaga,
  themePageSaga,
  homeSaga,
  siteInfoSaga,
  cancellationSaga,
  refundSaga,
  cookieSaga
];

export default function* rootSaga() {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.error('Unexpected saga termination', error);
          }
        }
      })
    )
  );
}
