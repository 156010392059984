import { createSlice } from '@reduxjs/toolkit';

// Initial state for Refund slice
const initialState = {
    section1: {},
};

const refundSlice = createSlice({
    name: 'refund',
    initialState,
    reducers: {
        setRefundSection: (state, action) => {
            const { payload } = action;
            state[payload.sectionName] = payload.data;
        },
    },
});

export const refundActions = refundSlice.actions;
export const refundReducer = refundSlice.reducer;

export const RefundActionTypes = {
    FETCH_REFUND: 'Refund/Fetch',
};