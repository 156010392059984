import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class CookieService {
    async getCookieSection(payload) {
        const response = await HttpClient.get(OMD_API_ENDPOINTS.COOKIE_PAGE);
        return response;
    }
}

const cookieService = new CookieService();
export default cookieService;
