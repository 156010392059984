import { combineReducers } from '@reduxjs/toolkit';
import { privacyReducer } from './slices/privacySlice';
import { termsOfUseReducer } from './slices/termsOfUseSlice';
import { faqReducer } from './slices/faqSlice';
import { contactUsReducer } from './slices/contactUsSlice';
import { careersReducer } from './slices/careersSlice';
import { aboutUsReducer } from './slices/aboutUsSlice';
import { servicesReducer } from './slices/servicesSlice';
import { projectsReducer } from './slices/projectsSslice';
import { blogPageReducer } from './slices/blogSlice';
import { themePageReducer } from './slices/themePageSlice';
import { homeReducer } from './slices/homeSlice';
import { siteInfoReducer } from './slices/siteInfoSlice';
import { loaderReducer } from './slices/loaderSlice';
import { cancellationReducer } from './slices/cancellationSlice';
import { refundReducer } from './slices/refundSlice';
import { cookieReducer } from './slices/cookieSlice';

const appReducer = combineReducers({
  privacy: privacyReducer,
  termsOfUse: termsOfUseReducer,
  faq: faqReducer,
  contactUs: contactUsReducer,
  careers: careersReducer,
  aboutUs: aboutUsReducer,
  services: servicesReducer,
  projects: projectsReducer,
  blog: blogPageReducer,
  themePage: themePageReducer,
  home: homeReducer,
  siteInfo: siteInfoReducer,
  loader: loaderReducer,
  cancellation: cancellationReducer,
  refund: refundReducer,
  cookie: cookieReducer,


});

/**
 * To initialize the combined state
 */
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
