import React, { useEffect, useRef } from 'react';
import Header from '../../components/ThemePage/Header/Header';
import Variety from '../../components/ThemePage/Variety/Variety';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

const ThemePage = () => {
  const { SectionData } = useSelector((state) => state.themePage);

  // Create refs for each section
  const headerRef = useRef(null);
  const varietyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { id: 'theme-header', ref: headerRef },
        { id: 'themes', ref: varietyRef },
      ];

      for (let section of sections) {
        const { id, ref } = section;
        const offsetTop = ref.current?.offsetTop;
        const offsetHeight = ref.current?.offsetHeight;
        const scrollPosition = window.scrollY + window.innerHeight / 2;

        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          window.history.replaceState(null, null, `#${id}`);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <Helmet>{SectionData && parse(SectionData.SeoTags)}</Helmet>
      <section id='theme-header' ref={headerRef}>
        <Header SectionData={SectionData} />
      </section>
      <section id='themes' ref={varietyRef}>
        <Variety SectionData={SectionData} />
      </section>
    </>
  );
};

export default ThemePage;
