import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import cookieService from '../services/CookieService';
import { CookieActionTypes, cookieActions } from '../store/slices/cookieSlice';

// saga handler to fetch Cancellation sectionwise and update redux store as per the api payload
export const fetchCookieSection = function* (action) {
    try {
        const response = yield call(
            cookieService.getCookieSection,
            action.payload
        );
        const responseData = yield get(response, 'data');

        yield put(
            cookieActions.setCookieSection({
                sectionName: 'SectionData',
                data: responseData,
            })
        );
    } catch (err) {
        const errorMessage = err?.response?.data?.message || err.message;
        console.error(errorMessage);
    }
};

export default function* cookieSaga() {
    yield takeLatest(CookieActionTypes.FETCH_COOKIE, fetchCookieSection);
}
