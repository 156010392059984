import { createSlice } from '@reduxjs/toolkit';

// Initial state for Refund slice
const initialState = {
    section1: {},
};

const cookieSlice = createSlice({
    name: 'cookie',
    initialState,
    reducers: {
        setCookieSection: (state, action) => {
            const { payload } = action;
            state[payload.sectionName] = payload.data;
        },
    },
});

export const cookieActions = cookieSlice.actions;
export const cookieReducer = cookieSlice.reducer;

export const CookieActionTypes = {
    FETCH_COOKIE: 'Cookie/Fetch',
};