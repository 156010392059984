import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class CancellationService {
    // POST request to fetch Cancellation policy page section wise
    async getCancellationSection(payload) {
        const response = await HttpClient.get(OMD_API_ENDPOINTS.CANCELLATION_PAGE);
        return response;
    }
}

const cancellationService = new CancellationService();
export default cancellationService;
