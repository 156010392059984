import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import termsOfUseService from '../services/TermsOfUseService';
import {
  TermsOfUseActionTypes,
  termsOfUseActions,
} from '../store/slices/termsOfUseSlice';

// saga handler to fetch TermsOfUse sectionwise and update redux store as per the api payload
export const fetchTermsOfUseSection = function* (action) {
  try {
    const response = yield call(
      termsOfUseService.getTermsOfUseSection,
      action.payload
    );
    const responseData = yield get(response, 'data');
    yield put(
      termsOfUseActions.setTermsOfUseSection({
        sectionName: 'SectionData',
        data: responseData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* termsOfUseSaga() {
  yield takeLatest(
    TermsOfUseActionTypes.FETCH_TERMS_OF_USE,
    fetchTermsOfUseSection
  );
}
