import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class ProjectsService {
  // POST request to fetch Projects page section wise
  async getProjectsSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.PROJECT_PAGE);

    return response;
  }
}

const projectsService = new ProjectsService();
export default projectsService;
