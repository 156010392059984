import React, { useEffect, useRef } from 'react';
import Header from '../../components/CareersPage/Header/Header';
import Description from '../../components/CareersPage/Description/Description';
import Position from '../../components/CareersPage/Position/Position';
import { useDispatch, useSelector } from 'react-redux';
import { getCareersSection } from '../../actions/careers';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

const CareersPage = () => {
  const dispatch = useDispatch();
  const { SectionData } = useSelector((state) => state.careers);

  // Create refs for each section
  const headerRef = useRef(null);
  const descriptionRef = useRef(null);
  const positionRef = useRef(null);

  useEffect(() => {
    dispatch(getCareersSection({ sectionName: '' }));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        { id: 'careers-header', ref: headerRef },
        { id: 'description', ref: descriptionRef },
        { id: 'open-positions', ref: positionRef },
      ];

      for (let section of sections) {
        const { id, ref } = section;
        const offsetTop = ref.current?.offsetTop;
        const offsetHeight = ref.current?.offsetHeight;
        const scrollPosition = window.scrollY + window.innerHeight / 2;

        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          window.history.replaceState(null, null, `#${id}`);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <>
      <Helmet>{SectionData && parse(SectionData.SeoTags)}</Helmet>
      <section id='careers-header' ref={headerRef}>
        <Header SectionData={SectionData} />
      </section>
      <section id='description' ref={descriptionRef}>
        <Description SectionData={SectionData} />
      </section>
      <section id='open-positions' ref={positionRef}>
        <Position SectionData={SectionData} />
      </section>
    </>
  );
};

export default CareersPage;
