import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import careersService from '../services/CareersService';
import {
  CareersActionTypes,
  careersActions,
} from '../store/slices/careersSlice';

// saga handler to fetch careers sectionwise and update redux store as per the api payload
export const fetchCareersSection = function* (action) {
  try {
    const response = yield call(
      careersService.getCareersSection,
      action.payload
    );
    const caareersResponse = yield get(response, 'data');
    yield put(
      careersActions.setCareersSection({
        sectionName: 'SectionData',
        data: caareersResponse,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* careersSaga() {
  yield takeLatest(CareersActionTypes.FETCH_CAREERS, fetchCareersSection);
}
