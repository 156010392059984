import { createSlice } from '@reduxjs/toolkit';

// Initial state for Home slice
const initialState = {
  section1: {},
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomeSection: (state, action) => {

      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});


export const homeActions = homeSlice.actions;
export const homeReducer = homeSlice.reducer;

export const HomeActionTypes = {
  FETCH_HOME: 'Home/Fetch',
};
