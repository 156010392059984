import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import cancellationService from '../services/CancellationService';
import {
    CancellationActionTypes,
    cancellationActions,
} from '../store/slices/cancellationSlice';

// saga handler to fetch Cancellation sectionwise and update redux store as per the api payload
export const fetchCancellationSection = function* (action) {
    try {
        const response = yield call(
            cancellationService.getCancellationSection,
            action.payload
        );
        const responseData = yield get(response, 'data');

        yield put(
            cancellationActions.setCancellationSection({
                sectionName: 'SectionData',
                data: responseData,
            })
        );
    } catch (err) {
        const errorMessage = err?.response?.data?.message || err.message;
        console.error(errorMessage);
    }
};

export default function* cancellationSaga() {
    yield takeLatest(CancellationActionTypes.FETCH_CANCELLATION, fetchCancellationSection);
}
