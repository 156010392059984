import React, { useEffect } from 'react';
import parse from 'html-react-parser';

const Header = ({ SectionData }) => {

  let title = '';
  let image = '';
  let imageAlt = '';
  let image2 = '';
  let image2Alt = '';

  if (SectionData) {

    title = parse(SectionData?.Heading);
    image = SectionData?.HeaderImage?.url;
    imageAlt = SectionData?.HeaderImage?.alternativeText;
    image2 = SectionData?.HeaderImageMobile?.url;
    image2Alt = SectionData?.HeaderImageMobile?.alternativeText;
  }

  return (
    <div className='container-fluid p-0 StickScroll'>
      <div className='inSideHeader'>
        <img src={image} alt={imageAlt} className='WebImg' />
        <img src={image2} alt={image2Alt} className='MobileImg' />
        <div className='inSideHeaderCaption' data-aos='fade-up'>
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
