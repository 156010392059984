import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';



class ContactUsService {
  // POST request to fetch ContactUs page section wise
  async getContactUsSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.CONTACT_PAGE);
    return response;
  }
}

const contactUsService = new ContactUsService();
export default contactUsService;
