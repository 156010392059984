import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class ThemePageService {
  // POST request to fetch Theme Page page section wise
  async getThemePageSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.THEME_PAGE);
    return response;
  }
}

const themePageService = new ThemePageService();
export default themePageService;
