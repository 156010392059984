import careerImg from '../../../assets/careersHeader.jpg';
import careerImgMobile from '../../../assets/careersHeaderMobile.jpg';
import parse from 'html-react-parser';

const CareersPage = ({ SectionData }) => {
  let section1 = '';
  let title = '';
  let image = '';
  let imageAlt = '';
  let image2 = '';
  let image2Alt = '';

  if (SectionData) {

    title = parse(SectionData?.Heading);
    image = SectionData.BannerImage.url;
    imageAlt = SectionData.BannerImage.altTag;
    image2 = SectionData.BannerImageMobile.url;
    image2Alt = SectionData.BannerImageMobile.altTag;
  }
  return (
    <div className='container-fluid p-0'>
      <div className='inSideHeader'>
        <img src={image} alt={imageAlt} className='WebImg' />
        {/* <img src={careerImg} alt='careerImg' className='WebImg' /> */}
        <img src={image2} alt={image2Alt} className='MobileImg' />
        {/* <img src={careerImgMobile} alt='careerImg' className='MobileImg' /> */}
        <div className='inSideHeaderCaption' data-aos='fade-right'>
          <h1>{title}</h1>
          {/* <h1>
            careers at outmazed<sup>&reg;</sup> design
          </h1> */}
        </div>
      </div>
    </div>
  );
};

export default CareersPage;
