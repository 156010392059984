import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import ThemePageService from '../services/ThemePageService';

import {
  ThemePageActionTypes,
  themePageActions
} from '../store/slices/themePageSlice';


// saga handler to fetch Theme Page sectionwise and update redux store as per the api payload
export const fetchThemePageSection = function* (action) {
  try {
    const response = yield call(
      ThemePageService.getThemePageSection,
      action.payload
    );
    const responseData = yield get(response, 'data');

    yield put(
      themePageActions.setThemePageSection({
        sectionName: 'SectionData',
        data: responseData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* themePageSaga() {
  yield takeLatest(ThemePageActionTypes.FETCH_THEME_PAGE, fetchThemePageSection);
}