import { createSlice } from '@reduxjs/toolkit';

// Initial state for projects slice
const initialState = {

  SectionData: {}
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjectsSection: (state, action) => {

      const { sectionName, data } = action.payload;
      state.SectionData = data;

    },
  },
});

export const projectsActions = projectsSlice.actions;
export const projectsReducer = projectsSlice.reducer;

export const ProjectsActionTypes = {
  FETCH_PROJECTS: 'Projects/Fetch',
};
