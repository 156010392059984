import parse from 'html-react-parser';

const Body = ({ section1 }) => {
  const description = section1?.Description;
  let parsedString = '';

  if (description) {
    parsedString = parse(description.replace(/\n/g, ''));
  }

  return (
    <div className='GlobalContainerNonBg'>
      <div className='container zIndex2'>
        <div className='row align-items-center'>
          <div className='col-md-12'>
            <div className='PrivacyPolicyText'>{parsedString}</div>
            {/* <div className='PrivacyPolicyText'>
              <p>
                At OutMazed<sup>&reg;</sup>, we respect your privacy and are
                committed to protecting your personal information. This Privacy
                Policy outlines how we collect, use, and disclose your personal
                information when you use our website, mobile application, or
                other services provided by OutMazed<sup>&reg;</sup>.
              </p>
              <br />
              <h2>Collection of Personal Information</h2>
              <p>
                We collect personal information that you voluntarily provide to
                us when you use our website, mobile application, or other
                services provided by OutMazed<sup>&reg;</sup>. Information that
                we collect about you may include:
              </p>
              <ul>
                <li>
                  Name, email address, phone number, and postal address are all
                  examples of contact information.
                </li>
                <li>
                  Information about billing and payments, such as your credit
                  card number and billing address.
                </li>
                <li>
                  Demographic data such as your age, gender, and employment.
                </li>
                <li>
                  User-generated content, such as reviews, comments, and other
                  submissions you make on our website or mobile application.
                </li>
                <li>
                  Location data, such as your IP address or GPS location data.
                </li>
                <li>
                  Information from social media platforms if you choose to
                  connect with OutMazed<sup>&reg;</sup>&nbsp;using your social
                  media account.
                </li>
              </ul>
              <br />
              <h2>Use of Personal Information</h2>
              <p>
                The personal information we gather may be used for the following
                purposes:
              </p>
              <ul>
                <li>To deliver and improve our services to you.</li>
                <li>
                  To process your transactions and requests, such as booking a
                  tour or purchasing a product.
                </li>
                <li>
                  To contact with you regarding your account or our services.
                </li>
                <li>
                  To personalize your experience with OutMazed<sup>&reg;</sup>.
                </li>
                <li>To conduct market research and analysis.</li>
                <li>To comply with legal obligations or as required by law.</li>
                <li>Sharing of Personal Information</li>
              </ul>
              <p>
                It may be necessary for us to share your personal information
                with third parties in the following circumstances:
              </p>
              <ul>
                <li>
                  With our service providers who perform services on our behalf,
                  such as payment processing and customer support.
                </li>
                <li>
                  With our business partners and affiliates in order to provide
                  you with relevant products and services.
                </li>
                <li>
                  If required by law, with law enforcement or other government
                  officials.
                </li>
                <li>
                  In the event that we combine, acquire, or sell all or a
                  portion of our assets.
                </li>
              </ul>
              <br />
              <h2>Security of Personal Information</h2>
              <p>
                We take reasonable precautions to prevent unauthorised access,
                disclosure, modification, or destruction of your personal
                information. To secure your personal information, we employ
                industry-standard security methods such as encryption and
                firewalls. Unfortunately, no method of internet transmission or
                computer storage is completely safe, and we cannot guarantee the
                perfect security of your personal information.
              </p>
              <br />
              <h2>Your Rights</h2>
              <p>
                You have the right to view, update, and remove any personal
                information that we have on record for you. You may also have
                the right to restrict or object to certain types of personal
                information processing. Please contact us using the details
                provided below to exercise these rights.
              </p>
              <br />
              <h2>Changes to this Privacy Policy</h2>
              <p>
                We may amend our Privacy Policy from time to time in response to
                new legal, technological, or commercial events. We will provide
                notice of any material changes by posting the updated Privacy
                Policy on our website or mobile application and updating the
                effective date. By continuing to use our services after the
                effective date, you accept the updated Privacy Policy.
              </p>
              <br />
              <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns about our Privacy Policy
                or our use of your personal information, please contact us at
                <a href='mailto:info@outmazeddesign.com'>
                  &nbsp;info@outmazeddesign.com.
                </a>
              </p>
              <p>Last updated: March 12, 2023</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
