import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import privacyService from '../services/PrivacyService';
import {
  PrivacyActionTypes,
  privacyActions,
} from '../store/slices/privacySlice';

// saga handler to fetch Privacy sectionwise and update redux store as per the api payload
export const fetchPrivacySection = function* (action) {
  try {
    const response = yield call(
      privacyService.getPrivacySection,
      action.payload
    );
    const responseData = yield get(response, 'data');

    yield put(
      privacyActions.setPrivacySection({
        sectionName: 'SectionData',
        data: responseData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* privacySaga() {
  yield takeLatest(PrivacyActionTypes.FETCH_PRIVACY, fetchPrivacySection);
}
