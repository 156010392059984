import { createSlice } from '@reduxjs/toolkit';

// Initial state for Cancellation slice
const initialState = {
    section1: {},
};

const cancellationSlice = createSlice({
    name: 'cancellation',
    initialState,
    reducers: {
        setCancellationSection: (state, action) => {
            const { payload } = action;
            state[payload.sectionName] = payload.data;
        },
    },
});

export const cancellationActions = cancellationSlice.actions;
export const cancellationReducer = cancellationSlice.reducer;

export const CancellationActionTypes = {
    FETCH_CANCELLATION: 'Cancellation/Fetch',
};