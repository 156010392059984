import getFooterDetails, { socialLinks } from './FooterConfig';
import logo from '../../assets/footerLogo.svg';
import cardsLogo from '../../assets/cardsLogo.png';

import { Link } from 'react-router-dom';
import { PAGE_URL } from '../../config/appConfig';
import {
  FaFacebookF,
  FaYoutube,
  FaXTwitter,
  FaInstagram,
  FaTiktok,
  FaLinkedinIn,
  FaWhatsapp,
  FaLocationDot,
} from 'react-icons/fa6';
import { BsTelephoneFill, BsEnvelopeFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { useProjectDetails } from '../ProjectsPage/ProjectsConfig';
import useServiceFeatures from '../ServicePage/Features/FeaturesConfig';

const FooterMobile = () => {
  const [service, setService] = useState([]);
  const [project, setProject] = useState([]);
  const [theme, setTheme] = useState([]);
  const projects = useProjectDetails();
  const services = useServiceFeatures();
  const { SectionData } = useSelector((state) => state.siteInfo);

  const { SectionData: themes } = useSelector((state) => state.themePage);
  const store = useSelector((state) => state);

  useEffect(() => {
    if (projects) {
      const newProjects = projects?.map(({ title }) => ({
        label: `Project ${title.split(' ').map(capitalize).join(' ')}`,
        href: `${PAGE_URL.PROJECTS}/${title
          .toLowerCase()
          .replace(/\s+/g, '-')}`,
      }));
      setProject(newProjects);
    }
    if (services) {
      const newServices = services?.map(({ heading }) => ({
        label: heading.split(' ').map(capitalize).join(' '),
        href: `${PAGE_URL.SERVICE}#${heading
          .toLowerCase()
          .replace(/\s+/g, '_')}`,
      }));
      setService(newServices);
    }
    if (themes) {
      const newThemes = themes.Themes?.Themes?.map(({ Title }) => ({
        label: Title.split(' ').map(capitalize).join(' '),
        href: `${PAGE_URL.THEME}#${Title.toLowerCase().replace(
          /\s+/g,
          '_'
        )}`,
      }));
      setTheme(newThemes);
    }
  }, [store]);

  return (
    <div className='row MobileFooter d-md-none d-block'>
      <div className='col-md-12 Fcol Fcol1 text-start' data-aos='fade-right'>
        <Link className='' to=''>
          <img
            src={SectionData && SectionData?.SiteFooterLogo?.url}
            className='footerLogo'
            alt={SectionData ? SectionData?.SiteFooterLogo.altTag : ''}
          />
        </Link>
      </div>
      <div className='col-md-12'>
        <div className='accordion' id='accordionExample'>
          {getFooterDetails().map((footerDetail, index) => (
            <div
              className={`accordion-item ${footerDetail?.btnClass}`}
              key={index}
            >
              <h2 className='accordion-header'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#collapse${index + 1}`}
                  aria-expanded={'false'}
                  aria-controls={`#collapse${index + 1}`}
                >
                  {footerDetail.detailName}
                </button>
              </h2>
              <div
                id={`collapse${index + 1}`}
                className={`accordion-collapse collapse ${''}`}
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  {footerDetail.detailName === 'SERVICES' ? (
                    <>
                      {service.map((children, index) => (
                        <ul key={index}>
                          <li>
                            <Link to={children.href}>{children.label}</Link>
                          </li>
                        </ul>
                      ))}
                    </>
                  ) : footerDetail.detailName === 'PROJECTS' ? (
                    <>
                      {project.map((children, index) => (
                        <ul key={index}>
                          <li>
                            <Link to={children.href}>{children.label}</Link>
                          </li>
                        </ul>
                      ))}
                    </>
                  ) : footerDetail.detailName === 'THEMES' ? (
                    <>
                      {theme.map((children, index) => (
                        <ul key={index}>
                          <li>
                            <Link to={children.href}>{children.label}</Link>
                          </li>
                        </ul>
                      ))}
                    </>
                  ) : (
                    <>
                      {footerDetail.subHeading.map((children, index) => (
                        <ul key={index}>
                          <li>
                            <Link to={children.href}>{children.label}</Link>
                          </li>
                        </ul>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className='accordion-item'>
            <h2 className='accordion-header mobileAcc'>
              <button className='accordion-button'>Get In Touch</button>
            </h2>
            <div id='collapseFive' className='accordion-collapse show'>
              <div className='accordion-body'>
                <ul>
                  <li>
                    <a
                      href={`tel://${SectionData && SectionData?.SitePhoneNumber}`}
                    >
                      <BsTelephoneFill />
                      {SectionData && SectionData?.SitePhoneNumber}
                    </a>
                  </li>
                  <li>
                    <a
                      href={`mailto:${SectionData && SectionData?.SiteEmail
                        }`}
                    >
                      <BsEnvelopeFill />
                      {SectionData && SectionData?.SiteEmail}
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <FaLocationDot />
                      {SectionData && SectionData?.SiteAddress}
                    </a>
                  </li>
                </ul>
                <div className='SocialMediaLink'>
                  {socialLinks?.map(
                    (socialItem, index) =>
                      SectionData && SectionData[socialItem.cmsUrl] && (
                        <a
                          target='_blank'
                          href={
                            SectionData[socialItem.cmsUrl]
                          }
                          key={index}
                        >
                          {socialItem.icon}
                        </a>
                      )


                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row text-center text-white d-md-none d-flex justify-content-center py-2'>
        <div>
          <img src={cardsLogo} alt="Cards Logos" width="320px" />
        </div>

      </div>
      <div className='col-md-12 p-0'>
        <div className='Finfo d-md-none d-block'>
          <ul>
            <li>
              &copy; {new Date().getFullYear()} Outmazed<sup>&reg;</sup> Design. All Rights Reserved
            </li>
            <li>
              <Link to={PAGE_URL.TERMS_OF_USE}>Terms of Use</Link>
            </li>
            <li>
              <Link to={PAGE_URL.PRIVACY}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={PAGE_URL.FAQ}>FAQs</Link>
            </li>
            <li>
              <Link to={PAGE_URL.CANCELLATION_PAGE}>Cancellation Policy</Link>
            </li>
            <li>
              <Link to={PAGE_URL.REFUND_PAGE}>Refund Policy</Link>
            </li>
            <li>
              <Link to={PAGE_URL.COOKIE_PAGE}>Cookie Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
