import { useSelector } from 'react-redux';

const usePositionData = () => {
  return useSelector((state) => {
    const { SectionData } = state.careers;

    const getPositionKey = (position) => {
      return position && position.toLowerCase().replace(/\s+/g, '-');
    };

    let positions = [];
    if (SectionData) {
      positions = SectionData.OpenPositions.OpenPositions.map(
        (job) => ({
          id: job?.id,
          JobPosition_Section3: job.Title.toUpperCase(),
          JobLocation_Section3: job.Location,
          JobDescription_Section3: job.Description,
          key: getPositionKey(job.Title),
        })
      );
    }

    return positions;
  });
};

export default usePositionData;
