import { useSelector } from 'react-redux';

const useDescriptionData = () => {
  return useSelector((state) => {
    const { SectionData } = state.careers;
    let jobData = [];


    if (SectionData) {
      jobData = SectionData.Benefits.Benefits.map(
        ({ Title, Description }) => ({
          heading: Title,
          detail: Description,
        })
      );
    }


    return jobData;
  });
};

export default useDescriptionData;
