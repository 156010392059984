import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import servicesService from '../services/ServicesService';
import {
  ServicesActionTypes,
  servicesActions,
} from '../store/slices/servicesSlice';

// saga handler to fetch Services sectionwise and update redux store as per the api payload
export const fetchServicesSection = function* (action) {
  try {
    const response = yield call(
      servicesService.getServicesSection,
      action.payload
    );
    const responseData = yield get(response, 'data');
    yield put(
      servicesActions.setServicesSection({
        sectionName: 'SectionData',
        data: responseData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* servicesSaga() {
  yield takeLatest(ServicesActionTypes.FETCH_SERVICES, fetchServicesSection);
}
