import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';

class RefundService {
    async getRefundSection(payload) {
        const response = await HttpClient.get(OMD_API_ENDPOINTS.REFUND_PAGE);
        return response;
    }
}

const refundService = new RefundService();
export default refundService;
