import React, { useEffect } from 'react';
import Header from '../../components/TermsOfUse/Header/Header';
import Body from '../../components/TermsOfUse/Body/Body';
import { useDispatch, useSelector } from 'react-redux';
import { getTermsOfUseSection } from '../../actions/termsOfUse';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

const TermsOfUse = () => {
  const dispatch = useDispatch();
  const { SectionData } = useSelector((state) => state.termsOfUse);



  useEffect(() => {
    dispatch(getTermsOfUseSection({ sectionName: '' }));
  }, []);

  return (
    <>
      <Helmet>{SectionData && parse(SectionData.SeoTags)}</Helmet>
      <Header section1={SectionData} />
      <Body section1={SectionData} />
    </>
  );
};

export default TermsOfUse;
