import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class PrivacyService {
  // POST request to fetch Privacy policy page section wise
  async getPrivacySection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.PRIVACY_PAGE);
    return response;
  }
}

const privacyService = new PrivacyService();
export default privacyService;
