import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PAGE_URL } from '../config/appConfig';
import Layout from '../components/Layout/Layout';
import CareersPage from '../pages/CareersPage/CareersPage';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import ThemePage from '../pages/ThemePage/ThemePage';
import TermsOfUse from '../pages/TermsOfUse/TermsOfUse';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import { Spinner } from 'react-bootstrap';

const HomePage = lazy(() => import('../pages/HomePage/HomePage'));
const AboutUsPage = lazy(() => import('../pages/AboutUsPage/AboutUsPage'));
const ServicePage = lazy(() => import('../pages/ServicePage/ServicePage'));
const Faq = lazy(() => import('../pages/FAQPage/FAQPage'));
const ContactUsPage = lazy(() =>
  import('../pages/ContactUsPage/ContactUsPage')
);
const ProjectsPage = lazy(() => import('../pages/ProjectsPage/ProjectsPage'));
const BlogsPage = lazy(() => import('../pages/BlogsPage/BlogsPage'));
const BlogDetailsPage = lazy(() =>
  import('../pages/BlogDetailsPage/BlogDetails')
);
const ProjectDetailsPage = lazy(() =>
  import('../pages/ProjectDetailsPage/ProjectDetails')
);
const CareerApplyPage = lazy(() =>
  import('../pages/CareerApplyPage/CareerApplyPage')
);
const GetQuote = lazy(() => import('../pages/GetQuote/GetQuote'));
const Flipbook = lazy(() => import('../pages/Flipbook/Flipbook'));
const ThankyouPage = lazy(() => import('../pages/ThankyouPage/ThankyouPage'));
const CancellationPage = lazy(() =>
  import('../pages/CancellationPage/CancellationPage')
);
const RefundPage = lazy(() => import('../pages/RefundPage/RefundPage'));
const CookiePolicyPage = lazy(() =>
  import('../pages/CookiePolicyPage/CookiePolicyPage')
);
const PaymentPage = lazy(() => import('../pages/PaymentPage/PaymentPage'));
const PaymentStatusPage = lazy(() => import('../pages/PaymentStatusPage/PaymentStatusPage'));

const AppRoutes = () => {
  return (
    <>
      <Layout id='xb-website'>
        <Suspense
          fallback={
            <div className='w-100 vh-100 d-flex justify-content-center align-items-center'>
              <Spinner animation='border' variant='secondary' />
            </div>
          }
        >
          <Routes>
            <Route
              index
              path='*'
              element={<Navigate to={PAGE_URL.HOMEPAGE} />}
            />
            <Route index path={PAGE_URL.HOMEPAGE} element={<HomePage />} />
            <Route path={PAGE_URL.ABOUT} element={<AboutUsPage />} />
            <Route path={PAGE_URL.SERVICE} element={<ServicePage />} />
            <Route path={PAGE_URL.CONTACT_US} element={<ContactUsPage />} />
            <Route path={PAGE_URL.CAREERS} element={<CareersPage />} />
            <Route path={PAGE_URL.APPLY_JOB} element={<CareerApplyPage />} />
            <Route path={PAGE_URL.PROJECTS} element={<ProjectsPage />} />
            <Route path={PAGE_URL.PRIVACY} element={<PrivacyPolicy />} />
            <Route path={PAGE_URL.BLOGS} element={<BlogsPage />} />
            <Route path={PAGE_URL.BLOG_DETAILS} element={<BlogDetailsPage />} />
            <Route
              path={PAGE_URL.PROJECT_DETAILS}
              element={<ProjectDetailsPage />}
            />
            <Route path={PAGE_URL.THEME} element={<ThemePage />} />
            <Route path={PAGE_URL.TERMS_OF_USE} element={<TermsOfUse />} />
            <Route path={PAGE_URL.FAQ} element={<Faq />} />
            <Route path={PAGE_URL.GET_QUOTE} element={<GetQuote />} />
            <Route path={PAGE_URL.FLIP_BOOK} element={<Flipbook />} />
            <Route path={PAGE_URL.THANK_YOU} element={<ThankyouPage />} />
            <Route path={PAGE_URL.CANCELLATION_PAGE} element={<CancellationPage />} />
            <Route path={PAGE_URL.REFUND_PAGE} element={<RefundPage />} />
            <Route path={PAGE_URL.COOKIE_PAGE} element={<CookiePolicyPage />} />
            <Route path={PAGE_URL.PAYMENT} element={<PaymentPage />} />
            <Route path={PAGE_URL.PAYMENT_STATUS} element={<PaymentStatusPage />} />


            {/* <Route path='*' element={<NotFoundPage />} /> */}
          </Routes>
        </Suspense>
      </Layout>


    </>
  );
};

export default AppRoutes;